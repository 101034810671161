<template>
  <div>
    <el-container>
      <el-header>
        <el-row>
          <el-col style="text-align: left;" :span="12">
            尊敬的
            <span style="color:red;font-weight:bold">{{ jiameng_name }}</span>
            ：{{ webname }}后台管理系统欢迎您，当前余额{{yue}}元！
          </el-col>
          <el-col style="text-align: right;" :span="12">
            <el-button-group>
              <el-button
                type="text"
                style="color:white;margin-right:20px;"
                icon="el-icon-bank-card"
                @click="bindWxDiv=true"
              >绑定微信</el-button>
              <el-button
                  type="text"
                  style="color:white;margin-right:20px;"
                  icon="el-icon-bank-card"
                  @click="tixian"
                  v-if="yue>1"
              >提现</el-button>
              <el-button
                type="text"
                style="color:white;margin-right:20px;"
                icon="el-icon-user"
              >{{ jiameng_name }}</el-button>
              <el-button
                type="text"
                style="color:white;margin-right:20px;"
                icon="el-icon-key"
                @click="chngPswdDiv = true"
              >修改密码</el-button>
              <el-button
                type="text"
                style="color:white;margin-right:20px;"
                icon="el-icon-edit"
                @click="chngpaidan"
                v-if="jiameng_level==2"
              >修改派单方式</el-button>
              <el-button
                type="text"
                style="color:white;margin-right:20px;"
                @click="logout"
                icon="el-icon-switch-button"
              >退出</el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </el-header>
      <el-container class="maincontent">
        <el-aside style="background:#ffffff" :width="aside_width">
          <el-button size="mini" @click="showmenu">{{ menu_act }}</el-button>
          <el-menu :unique-opened="true" :default-active="routepath" router :collapse="isCollapse">
            <el-submenu
              v-for="(item,index) in menulist[0]"
              :index="item.id+''"
              :key="index"
              router
              style="text-align:left"
            >
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group
                v-for="(it,ind) in menulist[item.id]"
                :index="it.id+''"
                :key="ind"
              >
                <el-menu-item :index="it.url" style="padding-left:50px;">{{ it.title }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
      <el-footer>
        <a href="http://beian.miit.gov.cn">鲁ICP备20002132号-1</a>
      </el-footer>
    </el-container>
    <el-dialog :visible.sync="chngPswdDiv">
      <el-form :model="pswdinfo" ref="pswdinfo" label-width="100px">
        <el-form-item label="原密码" :label-width="formLabelWidth" prop="type">
          <el-input v-model="pswdinfo.oldpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth" prop="price">
          <el-input v-model="pswdinfo.newpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" :label-width="formLabelWidth" prop="price">
          <el-input v-model="pswdinfo.cfrmnewpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="chngPswdDiv = false">取 消</el-button>
        <el-button type="primary" @click="chngpswd">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="tixianDiv">
      <el-form label-width="100px">
        <el-form-item label="提现金额" :label-width="formLabelWidth">
          <el-input v-model="tixianjine" autocomplete="off" type="number" min="1" :max="yue" step="1">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="tixianDiv = false">取 消</el-button>
        <el-button type="primary" @click="subtixian">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="bindWxDiv">
      <div><img style="width:80%;" src="../../assets/images/bindwx.jpg" alt=""></div>
    </el-dialog>
    <el-dialog :visible.sync="chngpaidandiv">
      <el-form :model="paidaninfo" label-width="100px">
        <el-form-item label="加盟地区" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="paidaninfo.id" placeholder="请选择地区" @change="initpaidan">
            <el-option
              v-for="(item,index) in paidanlist"
              :key="index"
              :label="item.area_text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="派单方式" :label-width="formLabelWidth" class="text-left">
          <el-radio-group v-model="paidaninfo.paidan">
            <el-radio-button label="1">抢单</el-radio-button>
            <el-radio-button label="2">派单</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closepaidan">取 消</el-button>
        <el-button type="primary" @click="subpaidan">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      webname: this.WEBNAME,
      jiameng_name: sessionStorage.getItem("jiameng_name"),
      jiameng_level: sessionStorage.getItem("jiameng_level"),
      menulist: JSON.parse(sessionStorage.getItem("jiameng_menulist")),
      isCollapse: false,
      urllist: JSON.parse(sessionStorage.getItem("jiameng_urllist")),
      chngPswdDiv: false,
      pswdinfo: {
        oldpassword: "",
        newpassword: "",
        cfrmnewpassword: ""
      },
      formLabelWidth: "150px",
      chngpaidandiv: false,
      paidaninfo: {
        id: "",
        paidan: 1
      },
      paidanlist: [],
      bindWxDiv: false,
      yue:0,
      tixianDiv:false,
      tixianjine:0
    };
  },
  methods: {
    ...mapActions({
      changePswd: "auth/changePassword"
    }),
    chngpswd() {
      if (this.pswdinfo.newpassword != this.pswdinfo.cfrmnewpassword) {
        this.$message({
          type: "error",
          message: "两次输入的新密码不一致"
        });
      } else {
        this.$axios({
          method: "post",
          url: this.HOST + "/jiameng/index/chngPswd",
          data: {
            info: this.pswdinfo
          }
        }).then(res => {
          if (res.data.status == 1) {
            this.$message({
              type: "info",
              message: "修改成功"
            });
            this.pswdinfo = {
              oldpassword: "",
              newpassword: "",
              cfrmnewpassword: ""
            };
            this.chngPswdDiv = false;
          }
        });
      }
    },
    chngpaidan() {
      this.$axios({
        url: this.HOST + "/jiameng/index/getpaidaninfo",
        method: "post",
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.paidanlist = res.data.list;
          this.chngpaidandiv = true;
        }
      });
    },
    initpaidan(value) {
      this.paidaninfo.paidan = this.crntpaidan[0].paidan;
    },
    subpaidan() {
      this.$axios({
        url: this.HOST + "/jiameng/index/setPaidan",
        method: "post",
        data: { info: this.paidaninfo }
      }).then(res => {
        if (res.data.status == 1) {
          this.chngpaidandiv = false;
          this.closepaidan();
        }
      });
    },
    closepaidan() {
      this.paidaninfo = {
        id: "",
        paidan: 1
      };
      this.chngpaidandiv = false;
    },
    logout() {
      sessionStorage.removeItem("jiameng_id");
      sessionStorage.removeItem("jiameng_name");
      sessionStorage.removeItem("jiameng_token");
      sessionStorage.removeItem("jiameng_login");
      sessionStorage.removeItem("jiameng_menulist");
      this.$router.push("/login");
    },
    changePassword() {
      this.$message("点击了修改密码");
      this.dialogVisible = true;
    },
    showmenu() {
      this.isCollapse = !this.isCollapse;
    },
    getYue(){
      this.$axios({
        url: this.HOST + "/jiameng/index/getYue",
        method: "post",
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.yue = res.data.yue;
        }
      });
    },
    tixian(){
      this.tixianjine = 1;
      this.tixianDiv = true;
    },
    subtixian(){
      if(this.tixianjine<1 || this.tixianjine>this.yue){
        this.$message({
          type:'error',
          message:'提现金额不正确'
        })
        return;
      }
      this.$axios({
        url:this.HOST+'/jiameng/index/tixian',
        method:'post',
        data:{jine:this.tixianjine}
      }).then(res=>{
        if(res.data.status == 1){
          this.message({
            type:'info',
            message:'提现成功'
          })
          this.tixianDiv = false;
          this.getYue();
        }
      });
    }
  },
  computed: {
    ...mapState({
      navList: state => state.navBar.navList
    }),
    routepath() {
      return this.$route.path;
    },
    menu_act() {
      return this.isCollapse ? "菜单" : "收起菜单";
    },
    aside_width() {
      return this.isCollapse ? "50px" : "200px";
    },
    crntpaidan() {
      return this.paidanlist.filter(d => {
        return (d.id = this.paidaninfo.id);
      });
    },
  },
  created() {
    this.getYue();
  }
};
</script>

<style>
.flex {
  display: flex;
  justify-content: space-between;
}
</style>
